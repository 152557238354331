<template>
  <!-- 政务大数据 -->
  <div class="content-box">
    <div class="title-box">
      <div class="title-name">政务大数据</div>
      <div class="title-p">
        政务大数据平台整合省社会经济发展资源，打造集数据采集、数据处理、监测管理、预测预警、应急指挥、可视化平台于一体的大数据平台，以信息化提升数据化管理与服务能力，及时准确掌握社会经济发展情况，做到“用数据说话、用数据管理、用数据决策、用数据创新”，牢牢把握社会经济发展主动权和话语权。
      </div>
    </div>

    <div class="function">
      <div class="function-title">平台架构</div>
      <div class="services">
        <div class="left"></div>
      </div>
    </div>
    <div class="function">
      <div class="function-title">功能场景</div>
    </div>
    <div class="scene">
      <h1></h1>
      <div class="img2" @click="go(1)">
        <div class="sceneBox">
          <div class="sceneIcon icon1"></div>
          <div class="sceneTitle">民生服务</div>
          <div class="sceneContent">
            多渠道采集食品药品安全、医疗卫生、社会保障、教育文化、旅游休闲、就业等方面的数据，推动数据的整合、共享和开放,搭载市民个人网页、便民移动应用等，提供全方位的一站式便民信息服务，推进均等化和人性化的民生服务。
          </div>
        </div>
      </div>
      <div class="img3" @click="go(2)">
        <div class="sceneBox">
          <div class="sceneIcon icon2"></div>
          <div class="sceneTitle">城市综合管理</div>
          <div class="sceneContent">
            以城市综合管理为对象，构建科学的预警监控体系，整合城市规划、交通、治安、城管、环境、气象等方面的数据资源，建立可视化和智能化的大数据应用分析模型，通过对海量数据的智能分类、整理和分析，使城市管理者能够更准确地预测可能出现的情况，及时调度资源。
          </div>
        </div>
      </div>
      <div class="img4" @click="go(3)">
        <div class="sceneBox">
          <div class="sceneIcon icon3"></div>
          <div class="sceneTitle">审批服务</div>
          <div class="sceneContent">
            整合各类服务软件的功能,涵盖全部业务,通过流程再造,打破信息孤岛,在互联网、多终端实现了‘一个平台内办两家事、业务办理全程电子化无纸化’”。
          </div>
        </div>
      </div>
      <div class="img5" @click="go(4)">
        <div class="sceneBox">
          <div class="sceneIcon icon4"></div>
          <div class="sceneTitle">大数据分析</div>
          <div class="sceneContent">
            基于数据源提供的数据，利用数据模型可视化分析后台，从不同维度对各种数据指标进行综合分析、分类对比，并对数据进行筛选、条数限制、数据公式计算等操作，生成不同的度量数据模型。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      console.log(scrollTop);
    },
    // go(i) {
    //   if (i == 1) {
    //     this.$router.push("livelihood");
    //   }
    //   if (i == 2) {
    //     this.$router.push("cityManagement");
    //   }
    //   if (i == 3) {
    //     this.$router.push("Examine");
    //   }
    //   if (i == 4) {
    //     this.$router.push("bigData");
    //   }
    // },
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script> 

<style scoped lang="scss">
.content-box {
  width: 100%;
  overflow: hidden;
  .title-box {
    width: 100%;
    color: #fff;
    height: 530px;
    padding: 0 160px;
    background: url("../../assets/image/governmentBigDataImg/bg.png") no-repeat;
    background-size: 100% 100%;
    .title-name {
      font-size: 44px;
      font-weight: normal;
      color: #ffffff;
      transform: translateY(190px);
    }
    .title-p {
      width: 55%;
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
      transform: translateY(220px);
      color: #ffffff;
    }
  }
  .function {
    padding: 0 10%;
    .function-title {
      text-align: center;
      padding: 30px 0;
      font-size: 40px;
      font-weight: 600;
      color: #333333;
    }
    .services {
      width: 100%;
      height: 700px;
      display: flex;
      background-color: #fff;
      .left {
        width: 100%;
        height: 700px;
        background: url("../../assets/image/governmentBigDataImg/组 292.png")
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .scene {
    padding: 0 10%;
    display: flex;
    margin-bottom: 50px;
    overflow: hidden;
    > div {
      flex: 1;
      &:hover {
        transform: scale(1.07, 1.07);
      }
    }
    .sceneBox {
      // cursor: pointer;
      &:hover {
        background: rgba(0, 0, 0, 0.6);
        opacity: 0.8;
        .sceneIcon {
          animation: moves 0.5s linear;
          animation-fill-mode: forwards;
        }
        .sceneTitle,
        .sceneContent {
          display: block;
        }
        @keyframes moves {
          0% {
          }
          100% {
            margin-top: 100px;
          }
        }
      }

      width: 100%;
      height: 500px;
      display: flex;
      flex-direction: column;
      align-items: center;
      // padding-top: 110px;
      box-sizing: border-box;

      .sceneIcon {
        margin-top: 180px;
        width: 44px;
        height: 44px;
        z-index: 99;
      }
      .sceneTitle {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #ffffff;
        margin: 5px 0;
        opacity: 1;
        z-index: 99;
        box-sizing: border-box;
        padding-bottom: 10px;
        border-bottom: 2px solid #fff;
      }
      .sceneContent {
        color: #fff;
        margin: 10px 40px;
        z-index: 99;
        display: none;
      }
    }
  }
  .img2 {
    background: url("../../assets/image/governmentBigDataImg/组 322.png")
      no-repeat;
    background-size: 100% 100%;
  }
  .img3 {
    background: url("../../assets/image/governmentBigDataImg/组 323.png")
      no-repeat;
    background-size: 100% 100%;
  }
  .img4 {
    background: url("../../assets/image/governmentBigDataImg/组 324.png")
      no-repeat;
    background-size: 100% 100%;
  }
  .img5 {
    background: url("../../assets/image/governmentBigDataImg/组 325.png")
      no-repeat;
    background-size: 100% 100%;
  }
  .icon1 {
    background: url("../../assets/image/governmentBigDataImg/icon1.png")
      no-repeat;
    background-size: 100% 100%;
  }
  .icon2 {
    background: url("../../assets/image/governmentBigDataImg/icon2.png")
      no-repeat;
    background-size: 100% 100%;
  }
  .icon3 {
    background: url("../../assets/image/governmentBigDataImg/icon3.png")
      no-repeat;
    background-size: 100% 100%;
  }
  .icon4 {
    background: url("../../assets/image/governmentBigDataImg/icon4.png")
      no-repeat;
    background-size: 100% 100%;
  }
}
</style>